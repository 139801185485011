html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
  background-color: whitesmoke;
  font-family: BBrandonTextA, CenturyGothic, AppleGothic, Century Gothic, sans-serif;
}
.hidden {
  display: none;
}
.cover {
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left:0;
  background-color: whitesmoke;
  z-index: 10;
}

#menu {
  width: 100%;
  height: 64px;
  display: inline-block;
}
/* -- Fullscreen -- */
.fullscreen {
  width: 100%;
  height: 100%;
}
.fullscreen-enabled {
  background-color: whitesmoke;
}
/* -- Slideshow -- */
.screen,
.loop {
  position: absolute;
  top: 0;
  display: flex;
  z-index: 1;
  padding: 8px;
}
.screen .playtext{
  margin-left: 8px;

}
.screen i,
.loop i {
  margin-bottom: 4px;
}
.screen {
  right:0;
}
.loop {
  left: 0;
}
.screen:hover,
.loop:hover,
.arrow_right:hover,
.arrow_left:hover {
  cursor: pointer;
  opacity: 0.6;
}


.playtext {
  display: flex;
  flex: 2;
  margin: auto;
  font-size: 16px;
}

.container {
  height:100%;
  width: 100%;
}
/* -- Book display -- */
.bookContainer {
  height: calc(100% - 128px);
  box-sizing: border-box;
  display: flex;
  width: 100%;
}

.book {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: 0 32px;
  width: 100%;
}

.navigation,
.arrow_right,
.arrow_left {
  display: flex;
  flex: 0;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  height: 100%;
}

.img {
  width: 100%;
  height: 100%;
}
.content {
  width: 100%;
  height: 100%;
}

/* -- Details -- */
.page,
.pagecover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.8);
}


.--left .gradient {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 94%, rgba(0, 0, 0, 0.3) 100%);

  width: 100%;
  height: 100%;
  position: absolute;
}

.--right .gradient {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.3) 0%, rgba(255, 255, 255, 0) 6%);

  width: 100%;
  height: 100%;
  position: absolute;
}

.page:hover,
.pagecover:hover {
  cursor: pointer;
}

/* -- Page number -- */
.numberpages {
  position: absolute ;
  bottom: 0;
  width: 100%;
  padding: 8px;
  text-align: right;
  font-size: 12px;
  box-sizing: border-box;
}

.numberpages:hover {
  cursor: default;
}

/* -- Error message -- */
.error {
  color: red;
  font-size: medium;
  font-family: BrandonTextA, CenturyGothic, AppleGothic, Century Gothic, sans-serif;
  position: absolute;
  top: 49%;
  font-size: 17px;
  width: 100%;
  text-align: center;
  font-weight: bold;
  text-shadow: 2px 2px 20px black;
}

@media only screen and (max-width: 600px) {
  .navigation{
    min-width: 40px;
  }
  .book {
    padding: 0;
  }
  .hide-for-small {
    display: none;
  }
}

@media only screen and (max-height: 400px) {
  .navigation{
    min-width: 40px;
  }
  .book {
    padding: 0;
  }
  .hide-for-small {
    display: none;
  }
}